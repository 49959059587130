@import url('./Theme/dx.material.panorama-datagrid-custom-scheme/dx.material.panorama-datagrid-custom-scheme.css');

body {
  font-family: 'Open Sans';
  margin: 0px;
  overflow: hidden;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff !important;
  /* box-shadow: inset 0 0 6px rgb(255, 255, 255);
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255); */
}

::-webkit-scrollbar {
  background-color: #ffffff !important;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #adadad !important;
  /* box-shadow: inset 0 0 6px rgb(255, 255, 255);
  -webkit-box-shadow: inset 0 0 6px rgb(255, 255, 255); */
}

.azure-maps-control-container {
  display: flex;
  width: min-content;
}

.atlas-legend-category-legend {
  width: 160px !important;
}

.atlas-legend-control {
  border-radius: 5px;
  height: 200px;
  display: contents;
}

#Service-Growth {
  display: block !important;
}

#ranking-legend {
  display: block !important;
}

#service-Population {
  display: block !important;
  right: 50px !important;
}

#demographics-population {
  display: block !important;
  position: inherit !important;
}

.atlas-legend-category-item {
  display: flex;
  padding: 5px;
}


.top-right.subcontrol-container {
  display: -webkit-inline-box;
}

.atlas-legend-category-shape {
  padding-right: 5px;
}

.bottom-right.subcontrol-container {
  position: absolute;
  bottom: 8px;
  right: 0;
}

input.dx-texteditor-input {
  font-size: 14px;
}

.dx-placeholder {
  font-size: 14px;
}

input#formatted-text-mask-input {
  font-size: 14px;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.atlas-legend-title {
  font-weight: 600 !important;
  margin-left: 5px !important;
}


.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #4CAF50;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse {
  background: #d6d4d4;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #4CAF50;
  animation-delay: 1.1s;
}


@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.popup-container {
  margin-left: 50px !important;
  transform: none !important;
  // height: 99%;
}